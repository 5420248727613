/* src/styles/globals.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0, 0%, 98%, 1;
        --navbar-background: 259, 78%, 51%, 1;
        --foreground-footer: 193, 10%, 34%, 1;
        --foreground: 0, 0%, 100%, 1;
        --arrowHome: 0, 0%, 67%, 1;

        --darkest: 188, 100%, 50%, 1;
        --card-background: 217, 81%, 6%, 1;

        --card-background-light: 0, 0%, 98%, 1;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;


        --purple: 259, 78%, 51%, 1;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --border-footer: 70, 4%, 73%, 0.4;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 240 70% 60%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --span: 0 0% 46%;

        --radius: 0.5rem;

        --bg-footer: 56, 11%, 86%, 1;

        --black-hsl: 188.47058824, 100%, 50%;


        --small-text-size: 0.8rem;
        --small-text-size-value: 0.8;
        --large-text-size: 1.2rem;
        --large-text-size-value: 1.2;
        --heading-font-line-height: 1.4em;
        --heading-1-size: 3.1rem;
        --heading-1-size-value: 3.1;
        --heading-2-size: 2.5rem;
        --heading-2-size-value: 2.5;
        --heading-3-size: 1.9rem;
        --heading-3-size-value: 1.9;
        --heading-4-size: 1.5rem;
        --heading-4-size-value: 1.5;
    }

    .dark {
        --background: 0, 2%, 11%, 1;
        --foreground-footer: 35, 10%, 66%, 1;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 200, 6%, 20%, 1;;
        --input: 216 34% 17%;

        --xcard: 224 71% 4%;
        --card: 230 17% 14%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;

        --border-footer: 203, 6%, 28%, 0.4;

        --bg-footer: 200, 4%, 17%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

,
:after,
:before {
    --tw-content: "";
}

    body {
        @apply bg-white text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
        @apply font-sofiaPro;
        @apply overflow-x-hidden;
    }
}

.lg\:flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.lg\:order-last {
    order: 2;
}

.lg\:order-first {
    order: 1;
}

/*SvgComponentLifecycle start*/
.cls-1 {
    fill: #fff;
    opacity: 0;
    cursor: pointer;
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.transition-opacity {
    transition: opacity 1s ease-in-out;
}

.responsive-image {
    width: 100%;
    height: auto;
    position: relative;
}


@layer utilities {
    .before\:bg-radial-gradient-circle-at-4\.5px-4\.5px-theme-colors-gray-600-4\.5px-transparent-0\:before {
        content: var(--tw-content);
        background-image: radial-gradient(
                circle at 4.5px 4.5px,
                #4b5563 4.5px,
                transparent 0
        );
    }
    .before\:bg-conic-gradient-from-180deg-transparent-theme-colors-blue-500-border-box\:before {
        content: var(--tw-content);
        background: conic-gradient(from 180deg, transparent, #3b82f6) border-box;
    }
    .before\:bg-linear-gradient-theme-colors-gray-100-theme-colors-gray-200-border-box\:before {
        content: var(--tw-content);
        background: linear-gradient(#f3f4f6, #e5e7eb) border-box;
    }

    .before\:mask-composite-exclude-important\:before {
        content: var(--tw-content);
        -webkit-mask-composite: xor !important;
        mask-composite: exclude !important;
    }

    .before\:mask-linear-gradient-white-0-0-padding-box-linear-gradient-white-0-0\:before {
        content: var(--tw-content);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    }

}

/*SvgComponentLifecycle end*/

@media screen and (max-width: 767px ) and (orientation: portrait) {
    .sqsrte-large {
        font-size: calc((var(--large-text-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    .sqsrte-large {
        font-size: calc((var(--large-text-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    .sqsrte-large {
        font-size: calc(var(--large-text-size-value) * 1rem)
    }
}

@media screen and (max-width: 767px ) and (orientation: portrait) {
    .sqsrte-small {
        font-size: calc((var(--small-text-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    .sqsrte-small {
        font-size: calc((var(--small-text-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    .sqsrte-small {
        font-size: calc(var(--small-text-size-value) * 1rem)
    }
}

h1 {
    line-height: calc(var(--heading-font-line-height) * (1 + (1 - var(--heading-1-size-value)) / 25))
}

@media screen and (max-width: 767px ) and (orientation: portrait) {
    h1 {
        font-size: calc((var(--heading-1-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    h1 {
        font-size: calc((var(--heading-1-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    h1 {
        font-size: calc(var(--heading-1-size-value) * 1rem)
    }
}

h2 {
    line-height: calc(var(--heading-font-line-height) * (1 + (1 - var(--heading-2-size-value)) / 25))
}

@media screen and (max-width: 767px ) and (orientation: portrait) {
    h2 {
        font-size: calc((var(--heading-2-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    h2 {
        font-size: calc((var(--heading-2-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    h2 {
        font-size: calc(var(--heading-2-size-value) * 1rem)
    }
}

h3 {
    line-height: calc(var(--heading-font-line-height) * (1 + (1 - var(--heading-3-size-value)) / 25))
}

@media screen and (max-width: 767px ) and (orientation: portrait) {
    h3 {
        font-size: calc((var(--heading-3-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    h3 {
        font-size: calc((var(--heading-3-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    h3 {
        font-size: calc(var(--heading-3-size-value) * 1rem)
    }
}

h4 {
    line-height: calc(var(--heading-font-line-height) * (1 + (1 - var(--heading-4-size-value)) / 25))
}

@media screen and (max-width: 767px ) and (orientation: portrait) {
    h4 {
        font-size: calc((var(--heading-4-size-value) - 1) * calc(.012 * min(100vh, 900px)) + 1rem)
    }
}

@media screen and (min-width: 768px ), screen and (max-width: calc(1856px - 1px)) and (orientation: landscape) {
    h4 {
        font-size: calc((var(--heading-4-size-value) - 1) * 1.2vw + 1rem)
    }
}

@media screen and (min-width: 1856px) {
    h4 {
        font-size: calc(var(--heading-4-size-value) * 1rem)
    }
}

svg {
    @apply text-white;
}

svg foreignObject {
    @apply flex w-1/2;
}

svg foreignObject .cls-left, svg foreignObject .cls-right {
    @apply w-4/5 text-3xl leading-none text-white fill-current font-sofiaPro text-center;
}

@media (max-width: 640px) {
    svg foreignObject .cls-left, svg foreignObject .cls-right {
        @apply text-4xl;
    }
}

svg foreignObject .cls-left {
    @apply pl-6;
}

svg foreignObject .cls-right {
    @apply ml-12;
}
